<template>
  <svg
    width="89"
    height="25"
    viewBox="0 0 89 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8036 4.81433C22.3818 5.57529 22.8713 6.402 23.2622 7.27787L12.1748 15.5977L7.54102 12.6409V9.08368L12.1748 12.0405L21.8036 4.81433Z"
      fill="white"
    />
    <path
      d="M2.812 12.3898C2.812 12.2302 2.81582 12.0716 2.82347 11.9139L0.0152938 11.7739C0.0057357 11.9782 3.39355e-06 12.1845 3.39355e-06 12.3927C-0.00119141 14.0193 0.313125 15.6302 0.924929 17.1328C1.53673 18.6355 2.43397 20.0005 3.56518 21.1493L5.55709 19.1227C4.68633 18.2399 3.99554 17.1905 3.52445 16.0351C3.05335 14.8796 2.81123 13.6408 2.812 12.3898Z"
      fill="white"
    />
    <path
      d="M12.1706 2.86174C12.3273 2.86174 12.4831 2.86758 12.6379 2.87536L12.7784 0.0158237C12.5771 0.00609411 12.3745 0.00122989 12.1706 0.00122989C10.5718 -0.000754714 8.98855 0.31874 7.51149 0.941367C6.03443 1.564 4.69275 2.4775 3.56348 3.62941L5.55539 5.65706C6.42268 4.77038 7.4536 4.06695 8.58888 3.58723C9.72416 3.10751 10.9413 2.86095 12.1706 2.86174Z"
      fill="white"
    />
    <path
      d="M12.1723 21.918C12.0156 21.918 11.8597 21.918 11.704 21.9054L11.5635 24.7639C11.7655 24.7743 11.9684 24.7796 12.1723 24.7796C13.7704 24.7812 15.3529 24.4615 16.8292 23.8387C18.3055 23.2159 19.6463 22.3023 20.7747 21.1504L18.7866 19.1237C17.9191 20.0099 16.8883 20.713 15.7533 21.1925C14.6182 21.6721 13.4013 21.9186 12.1723 21.918Z"
      fill="white"
    />
    <path
      d="M17.4484 4.5243L19.815 2.74864C17.6519 0.965644 14.9518 -0.00494939 12.1685 1.898e-05V2.8615C14.0532 2.85912 15.8942 3.43892 17.4484 4.5243Z"
      fill="white"
    />
    <path
      d="M24.3437 12.3898C24.3452 11.6454 24.2813 10.9023 24.1525 10.1695L21.5327 12.1349C21.5327 12.2196 21.5327 12.3042 21.5327 12.3898C21.5334 13.7211 21.2592 15.0377 20.7281 16.2542C20.1969 17.4707 19.4205 18.56 18.4492 19.4515L20.3389 21.5775C21.6016 20.4171 22.6106 18.9996 23.3005 17.4169C23.9904 15.8341 24.3458 14.1214 24.3437 12.3898Z"
      fill="white"
    />
    <path
      d="M12.1713 21.918C10.8633 21.9185 9.56989 21.6393 8.37465 21.0986C7.17947 20.5579 6.10914 19.7677 5.233 18.7792L3.14551 20.7018C4.28527 21.9873 5.67775 23.0146 7.23264 23.7171C8.78752 24.4195 10.4701 24.7815 12.1713 24.7795V21.918Z"
      fill="white"
    />
    <path
      d="M5.89546 5.32795L4.00677 3.20203C2.74373 4.36216 1.73435 5.77959 1.04409 7.36238C0.353837 8.94515 -0.00188187 10.658 7.48702e-06 12.3897H2.81201C2.81143 11.0585 3.08561 9.74189 3.61679 8.52538C4.14797 7.3089 4.92429 6.21955 5.89546 5.32795Z"
      fill="white"
    />
    <path
      d="M70.2398 7.08552L73.7225 4.50323H62.0667V20.0205H73.7225V17.4382H65.3925V13.4635H72.4984V10.8812H65.3925V7.08552H70.2398Z"
      fill="white"
    />
    <path
      d="M54.2238 10.5482H50.1772V7.08445H56.6355L60.1161 4.49432H46.8533V20.0116H53.7958C57.6881 20.0116 59.9219 18.2361 59.9219 15.1398C59.9219 12.1776 57.8986 10.5482 54.2238 10.5482ZM53.6461 17.4285H50.1772V13.1289H53.6461C55.7513 13.1289 56.7384 13.8494 56.7384 15.2787C56.7384 16.708 55.6881 17.4285 53.6461 17.4285Z"
      fill="white"
    />
    <path
      d="M82.2917 4.50098H75.947V20.0182H79.2724V15.6224H82.2917C86.3397 15.6224 88.8437 13.4787 88.8437 10.0534C88.8437 6.62821 86.3397 4.50098 82.2917 4.50098ZM82.2215 13.0382H79.2724V7.08523H82.2215C84.3507 7.08523 85.5258 8.14296 85.5258 10.0629C85.5258 11.9828 84.3507 13.0382 82.2215 13.0382Z"
      fill="white"
    />
    <path
      d="M41.864 16.3562C41.0049 16.8244 40.0424 17.0668 39.0656 17.0611C36.1419 17.0611 34.0209 14.9604 34.0209 12.0677C34.0209 9.175 36.1419 7.06977 39.0656 7.06977C40.12 7.05174 41.1525 7.37351 42.0122 7.98811L44.3384 6.26394L44.1811 6.15145C42.8241 4.95075 41.011 4.31482 38.9357 4.31482C36.6847 4.31482 34.6435 5.07702 33.1862 6.46368C32.4493 7.17765 31.8666 8.03682 31.4742 8.98758C31.0818 9.93833 30.8882 10.9602 30.9056 11.9896C30.89 13.0295 31.0827 14.0618 31.4722 15.0251C31.8617 15.9883 32.44 16.8627 33.1726 17.596C34.6367 19.0377 36.6733 19.832 38.9083 19.832C41.2436 19.832 43.2848 19.0124 44.6669 17.5202L42.5847 15.9591L41.864 16.3562Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSber",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
