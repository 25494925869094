<style lang='postcss'>
.success {
  color: #25ff48;
}
.error {
  color: #ff4444;
}
</style>

<template>
  <marquee-text
    :repeat="repeat"
    :duration="duration"
    :paused="paused"
    :reverse="reverse"
    class="font-chava text-base leading-6 text-white uppercase"
    @mouseover="paused = true"
    @mouseleave="paused = false"
  >
    <span class="mx-3">{{ textDivider }}</span>
    <span class="mx-3" :class="`${marquee.type}`">{{ marquee.text }}</span>
  </marquee-text>
</template>

<script>
export default {
  name: "RunningLine",
  components: {},
  data() {
    return {
      paused: false,
      textDivider: "@#$%ˆ&*()",
    };
  },
  props: {
    repeat: {
      type: Number,
      default: 10,
    },
    duration: {
      type: Number,
      default: 10,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    marquee() {
      return this.$store.state.game.marquee;
    },
  },
  
};
</script>
