<style lang="postcss">
.settings-modal {
  @apply rounded-2xl px-4 md:px-6 py-6;
  max-width: 100%;
  left: 0;
}
.close-icon {
  @apply absolute top-0 right-0;
}
</style>

<template>
  <div class="relative">
    <button-modal-close class="close-icon" @click.native="$emit('close')" />
    <g-activity-settings :user="user" :logout="logout" useDocs :docs="docs" />
  </div>
</template>

<script>
import ButtonModalClose from './ButtonModalClose.vue';
import { DOCS } from '@/utils/consts.js';

export default {
  name: 'SettinsModal',
  components: {
    gActivitySettings: () => import('@geeckocom/g-activity-settings'),
    ButtonModalClose,
  },
  data() {
    return {
      docs: [
        { name: 'Пользовательское соглашение', link: DOCS.AGREEMENT },
        {
          name: 'Согласие на обработку ПД',
          link: DOCS.PRIVACY,
        },
      ],
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
      this.$emit('close');
    },
    start() {
      this.$store.dispatch('game/startGame');
      this.$emit('close');
    },
  },
  computed: {
    user() {
      const currentUser = this.$store.state.user.currentUser;
      return {
        ...currentUser,
        link: `${process.env.VUE_APP_SERVER_URL}/profile`,
      };
    },
  },
};
</script>
