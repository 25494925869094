<style lang='postcss'>
.g-vtb-btn-icon {
  @apply border-none rounded-lg flex items-center justify-center;
  --size: 32px;
  height: 32px;
  width: 32px;
  background: rgba(236, 239, 248, 1);
  color: #858ea9;
  &:hover {
    background: rgba(236, 239, 248, 0.6);
    color: #58595b;
  }
  &:focus {
    outline: none;
  }
}
</style>

<template>
  <button class="g-vtb-btn-icon">
    <icon-cross />
  </button>
</template>

<script>
import IconCross from "./Icons/IconCross.vue";

export default {
  name: "ButtonModalClose",
  components: { IconCross },
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
