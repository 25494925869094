<style lang="postcss">
.main-layout {
  @apply flex flex-col overflow-hidden relative;
  background: #050505;
  background-image: url('/images/l-lines.svg'), url('/images/icons-left.svg'),
    url('/images/icons-right.svg');
  background-position: center, top -70px left calc(50% - 500px),
    top -70px right calc(50% - 500px);
  background-size: auto, 609px, 609px;
  background-repeat: no-repeat;
  min-height: 100vh;
  &__main-content {
    @apply flex-grow px-4 relative z-30 flex flex-col;
    min-height: calc(100vh - 100px);
  }
}
.g-header {
  @apply flex items-center justify-between relative z-20;
  max-width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  --icon-width: 125px;
  --vacancies-width: 154px;
  &__icon {
    @apply flex-shrink-0 px-5 box-border h-full;
    flex-basis: var(--icon-width);
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
  &__line {
    display: none;
    @media screen and (min-width: 560px) {
      display: block;
    }
  }
}

.g-footer {
  @apply relative z-20;
  &__line {
    @apply flex items-center;
    height: 50px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}
.ellipse-green {
  @apply absolute z-0;
  left: 30%;
  top: -250px;
  width: 1613px;
}
.ellipse-blue {
  @apply absolute z-0;
  right: 25%;
  top: -200px;
  width: 2186px;
}
.vacancies-link {
  @apply font-sb_sans_text uppercase text-white border-r border-l;
  font-size: 16px;
  width: var(--vacancies-width);
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  border-color: rgba(255, 255, 255, 0.4);
}
</style>

<template>
  <div class="main-layout">
    <img src="/images/ellipse-green.svg" class="ellipse-green" alt />
    <img src="/images/ellipse-blue.svg" class="ellipse-blue" alt />
    <div class="g-header">
      <icon-sber class="g-header__icon" />
      <div
        class="g-header__line"
        :class="{ 'with-user': !!currentUser }"
        :style="{
          width: `calc(100% - var(--icon-width) - ${userHeaderWidth}px)`
        }"
      >
        <running-line />
      </div>
      <user-header v-if="currentUser" ref="user-header" class="flex-shrink-0" />
      <!-- <router-link class="vacancies-link" :to="{ name: 'Job' }"
        >Вакансии</router-link -->
    </div>
    <div class="main-layout__main-content">
      <router-view></router-view>
    </div>
    <div v-if="withFooter" class="g-footer">
      <div class="g-footer__line">
        <running-line />
      </div>
      <footer-content />
    </div>
  </div>
</template>

<script>
import IconSber from '@/components/Icons/IconSber.vue';
import RunningLine from '@/components/UI/RunningLine.vue';
import UserHeader from '../components/UserHeader.vue';
import FooterContent from '../components/FooterContent.vue';

export default {
  components: { RunningLine, IconSber, UserHeader, FooterContent },
  name: 'main-layoutPage',
  data() {
    return {
      userHeaderWidth: 0
    };
  },
  props: {
    withFooter: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (this.$refs['user-header']) {
      this.userHeaderWidth = this.$refs['user-header'].$el.offsetWidth + 32;
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.user.currentUser;
    }
  }
};
</script>
