<style lang="postcss">
.footer {
  &-content {
    @apply relative w-full text-left md:pl-44
      pt-10 md:pt-12 mx-auto px-6 md:pr-0 pb-10 md:pb-18;
    max-width: 1000px;
    color: rgba(255, 255, 255, 0.7);
    .geecko-logo {
      @apply md:absolute left-0 md:left-4 md:top-12 mb-5;
      width: 113px;
      height: 30px;
    }
    .footer-link {
      @apply mb-2 md:mb-4 block md:mr-6;
      text-decoration: none;
      font-size: 16px;
      line-height: 24px;
      &:last-child {
        @apply md:mr-0;
      }
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
    .links {
      @apply flex flex-col md:flex-row mb-6 md:mb-9;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      color: #bcbcbc;
    }
  }
}
</style>

<template>
  <div class="footer-content">
    <icon-geecko class="geecko-logo" />
    <div class="links">
      <a
        v-for="item in links"
        :key="item.title"
        :href="item.link"
        class="footer-link"
        target="_blank"
        >{{ item.title }}</a
      >
      <router-link class="footer-link" :to="{ name: 'Top' }"
        >Топ игроков</router-link
      >
    </div>
    <div class="flex flex-col md:flex-row">
      <p class="w-149px md:mr-10 flex-shrink-0 mb-6 md:mb-0">
        Сделано программистами<br />для программистов с ❤️
      </p>
      <p>
        ООО «Смарт Гико». 125008, Россия, г. Москва, проезд Черепановых, д.
        36, ОГРН 1187746655359, ИНН 7743264341, КПП 774301001
      </p>
    </div>
  </div>
</template>
<script>
import IconGeecko from '@/components/Icons/IconGeecko.vue';
import { DOCS } from '@/utils/consts.js';
export default {
  components: { IconGeecko },
  name: 'FooterContent',
  data() {
    return {
      links: [
        {
          title: 'Правила конкурса',
          link: DOCS.RULES,
        },
        {
          title: 'Пользовательское соглашение',
          link: DOCS.AGREEMENT,
        },
        {
          title: 'Политика конфиденциальности',
          link: DOCS.PRIVACY,
        },
      ],
    };
  },
};
</script>
