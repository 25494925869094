<style lang='postcss'>
.g-user {
  @apply h-full font-chava text-white px-4 box-border whitespace-nowrap overflow-hidden overflow-ellipsis;
  font-size: 16px;
  line-height: 26px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  &:hover {
    opacity: 0.8;
  }
}
</style>

<template>
  <button class="g-user" @click="openSettings">
    {{ userName }}
  </button>
</template>

<script>
import SettingsModal from "./SettingsModal.vue";

export default {
  components: {},
  name: "UserHeader",
  props: {},
  created() {},
  mounted() {},
  methods: {
    openSettings() {
      this.$modal.show(
        SettingsModal,
        {},
        {
          name: "settings-modal",
          classes: "settings-modal",
          height: "auto",
          width: document.documentElement.clientWidth > 600 ? "600px" : "100%",
          clickToClose: true,
          scrollable: true,
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
  },
  computed: {
    userName() {
      return this.$store.state.user.currentUser?.username;
    },
  },
};
</script>
