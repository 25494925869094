<template>
  <svg
    width="114"
    height="30"
    viewBox="0 0 114 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7" clip-path="url(#geecko-icon)">
      <path
        d="M12.8154 10.6211V6.3707H8.99693C8.87943 6.3707 8.70319 6.3707 8.5857 6.3707C3.82728 6.3707 0.00878906 10.1553 0.00878906 14.8716C0.00878906 19.5296 3.82728 23.3724 8.5857 23.3724C10.1131 23.3724 11.5817 22.9649 12.8742 22.2662V25.5268H17.1626V10.6211H12.8154ZM11.5817 17.8411C10.8181 18.598 9.76062 19.0638 8.5857 19.0638C6.23586 19.0638 4.29725 17.1424 4.29725 14.8133C4.29725 13.6488 4.76721 12.6008 5.53091 11.8439C6.29461 11.0869 7.35204 10.6211 8.52696 10.6211C8.64445 10.6211 8.7032 10.6211 8.82069 10.6211C9.87812 10.6794 10.8768 11.1452 11.5817 11.8439C12.3454 12.6008 12.8154 13.6488 12.8154 14.8133C12.8154 16.0361 12.3454 17.0841 11.5817 17.8411Z"
        fill="white"
      />
      <path
        d="M73.3236 20.8688C71.7962 22.3826 69.6226 23.3724 67.2727 23.3724C62.573 23.3724 58.6958 19.5878 58.6958 14.8716C58.6958 12.5426 59.6357 10.3882 61.2219 8.87438C62.7493 7.36053 64.9229 6.3707 67.2727 6.3707H71.5612V10.6211H67.2727C66.0978 10.6211 65.0404 11.0869 64.2767 11.8439C63.513 12.6008 63.043 13.6488 63.043 14.8133C63.043 17.1423 64.9816 19.0638 67.3315 19.0638C68.5064 19.0638 69.5638 18.598 70.3275 17.841L73.3236 20.8688Z"
        fill="white"
      />
      <path d="M12.7569 25.4686H3.12256V29.719H12.7569V25.4686Z" fill="white" />
      <path
        d="M95.4713 19.1222H91.1829V23.3726H95.4713V19.1222Z"
        fill="white"
      />
      <path
        d="M86.9529 14.8718H91.1826V19.1223H82.6644V23.3727H78.376V0.0244141H82.6644V13.0668L89.4202 6.37095H95.4711L86.9529 14.8718Z"
        fill="white"
      />
      <path
        d="M105.575 6.3707C105.458 6.3707 105.34 6.3707 105.223 6.3707C105.105 6.3707 104.988 6.3707 104.87 6.3707C102.638 6.42892 100.64 7.36053 99.113 8.87438C97.5856 10.3882 96.5869 12.5426 96.5869 14.8716C96.5869 19.5296 100.405 23.3724 105.164 23.3724C107.514 23.3724 109.687 22.4408 111.215 20.8688C112.742 19.3549 113.741 17.2006 113.741 14.8716C113.799 10.2718 110.098 6.54537 105.575 6.3707ZM109.511 19.0638H100.934V10.6211H109.511V19.0638Z"
        fill="white"
      />
      <path
        d="M75.8497 10.6212H71.5613V14.8716H75.8497V10.6212Z"
        fill="white"
      />
      <path
        d="M37.0186 10.6211H32.7301V6.3707H28.4417C26.0918 6.3707 23.9182 7.3023 22.3908 8.87438C20.8634 10.3882 19.8647 12.5426 19.8647 14.8716C19.8647 19.5296 23.6832 23.3724 28.4417 23.3724H32.7301V19.122H28.4417C26.8555 19.122 25.5044 18.2486 24.7407 17.0259H37.0186V10.6211ZM24.4469 13.3577C24.5057 13.1248 24.6232 12.9501 24.7407 12.7755C24.9169 12.4843 25.1519 12.1932 25.4456 11.9021C26.2093 11.1452 27.2667 10.6794 28.4417 10.6794C28.5591 10.6794 28.6179 10.6794 28.7354 10.6794C29.7928 10.7376 30.7328 11.2034 31.4377 11.9021C31.6727 12.135 31.9077 12.4843 32.1427 12.7755C32.2601 12.9501 32.3189 13.183 32.4364 13.3577H24.4469Z"
        fill="white"
      />
      <path
        d="M56.4053 10.6796H52.1168V6.42914H47.8284C45.4785 6.42914 43.3049 7.36074 41.7775 8.93282C40.2501 10.4467 39.2515 12.601 39.2515 14.93C39.2515 19.588 43.07 23.4309 47.8284 23.4309H52.1168V19.1804H47.8284C46.2422 19.1804 44.8911 18.3071 44.1274 17.0843H56.4053V10.6796ZM43.8924 13.3579C43.9511 13.125 44.0686 12.9504 44.1861 12.7757C44.3624 12.4846 44.5973 12.1934 44.8911 11.9023C45.6548 11.1454 46.7122 10.6796 47.8871 10.6796C48.0046 10.6796 48.0634 10.6796 48.1808 10.6796C49.2383 10.7378 50.1782 11.2036 50.8832 11.9023C51.1181 12.1352 51.3531 12.4846 51.5881 12.7757C51.7056 12.9504 51.7644 13.1833 51.8818 13.3579H43.8924Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="geecko-icon">
        <rect width="113.75" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconGeecko",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
